import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';

import {
  atom,
  AtomEffect,
  SetterOrUpdater,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { queryClient } from '@/components/providers/Dependencies';
import { WaldoAPI, WaldoApiError } from '../../../lib/api';
import { getNextPageParam } from '../../utils/getNextPageParam';
import { localStorageEffect } from '../../utils/recoil';
import type {
  Team,
  SlugSuggestionResponse,
  TeamMember,
  TeamLens,
  SlugSuggestionInput,
  TeamInput,
  UpdateTeamInput,
  InviteMembersInput,
  ChangeRoleInput,
  DeleteMemberInput,
  AddTeamLensInput,
  DeleteTeamLensInput,
  FormFields,
  GenerateInviteCodeResponse,
  GenerateInviteCodeInput,
  TeamInvite,
  ChangeInviteRoleInput,
  DeleteInviteInput,
  TeamWithSharedLensAttribute,
  LeaveTeamInput,
  AcceptInviteInput,
  DeclineInviteInput,
  ResendInviteInput,
  TeamMemberRole,
} from './types';
import { AddonType, Subscription } from '@/ext/types';
import { submitForm } from '@/utils/submitForm';

export type {
  Team,
  TeamMember,
  TeamLens,
  TeamInvite,
  TeamInput,
  UpdateTeamInput,
  LeaveTeamInput,
  DeclineInviteInput,
  AcceptInviteInput,
  InviteMembersInput,
  ChangeRoleInput,
  ResendInviteInput,
  DeleteMemberInput,
  DeleteInviteInput,
  SlugSuggestionResponse,
  SlugSuggestionInput,
  DeleteTeamInput,
  AddTeamLensInput,
  DeleteTeamLensInput,
  GenerateInviteCodeInput,
  GenerateInviteCodeResponse,
  FormFields,
  TeamWithSharedLensAttribute,
} from './types';

export const keys = <const>{
  id: (id: number) => ['team', id],
  own: ['getTeams'],
  teams: (params: {
    lensId?: number;
    name?: string;
    skip?: number;
    limit?: number;
  }) => <const>['lens', 'teams', params],
  lenses: (teamId: number) => <const>['teams', 'lenses', teamId],
  invites: ['getInvites'],
  teamsBySlug: (slug: string) => <const>['teamsBySlug', slug],
  subscription: (teamId?: number) => <const>['getSubscription', teamId],
};

export class TeamApi extends WaldoAPI {
  private baseUrl = '/v1/teams';

  public async getById(teamId: number): Promise<Team> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/${teamId}`,
    });
  }

  public async getOwnTeams(): Promise<Team[]> {
    return this.request({
      method: 'GET',
      path: this.baseUrl,
    });
  }

  public async getTeamsWithSharedLensAttribute(
    lensId?: number,
    name?: string,
    skip?: number,
    limit?: number,
  ): Promise<TeamWithSharedLensAttribute[]> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/lens_is_shared`,
      query: {
        name,
        skip,
        limit,
        lensId,
      },
    });
  }

  public async getTeamsBySlug(slug: string): Promise<Team[]> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/find_by_slug`,
      query: {
        slug,
      },
    });
  }

  public async getSlugSuggestion(
    name: string,
  ): Promise<SlugSuggestionResponse> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/slug`,
      query: {
        name,
      },
    });
  }

  public async createTeam(
    name: string,
    slug: string,
    autoJoin?: boolean,
  ): Promise<Team> {
    return this.request({
      method: 'POST',
      path: this.baseUrl,
      body: {
        name,
        slug,
        autoJoin,
      },
    });
  }

  public async updateTeam(
    teamId: number,
    name: string,
    slug: string,
    autoJoin?: boolean,
  ): Promise<Team> {
    return this.request({
      method: 'PUT',
      path: `${this.baseUrl}/${teamId}`,
      body: {
        name,
        slug,
        autoJoin,
      },
    });
  }

  public async leaveTeam(teamId: number): Promise<TeamMember> {
    return this.request({
      method: 'DELETE',
      path: `${this.baseUrl}/${teamId}/leave`,
    });
  }

  public async acceptInvite(teamId: number): Promise<TeamMember> {
    return this.request({
      method: 'POST',
      path: `${this.baseUrl}/${teamId}/accept_invite`,
    });
  }

  public async declineInvite(teamId: number): Promise<TeamInvite> {
    return this.request({
      method: 'DELETE',
      path: `${this.baseUrl}/${teamId}/decline`,
    });
  }

  public async getInvites(): Promise<TeamInvite[]> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/invites`,
    });
  }

  public async inviteMembers(
    teamId: number,
    emails: string[],
  ): Promise<TeamInvite[]> {
    return this.request({
      method: 'POST',
      path: `${this.baseUrl}/${teamId}/members`,
      body: {
        emails,
      },
    });
  }

  public async changeMemberRole(
    teamId: number,
    teamMemberId: number,
    role: TeamMemberRole,
  ): Promise<TeamMember> {
    return this.request({
      method: 'PATCH',
      path: `${this.baseUrl}/${teamId}/members/${teamMemberId}/role`,
      body: {
        role,
      },
    });
  }

  public async changeInviteRole(
    teamId: number,
    teamInviteId: string,
    role: TeamMemberRole,
  ): Promise<TeamInvite> {
    return this.request({
      method: 'PATCH',
      path: `${this.baseUrl}/${teamId}/invites/${teamInviteId}`,
      body: {
        role,
      },
    });
  }

  public async resendInvite(
    teamId: number,
    teamInviteId: string,
  ): Promise<TeamInvite> {
    return this.request({
      method: 'PUT',
      path: `${this.baseUrl}/${teamId}/${teamInviteId}`,
    });
  }

  public async deleteMember(
    teamId: number,
    teamMemberId: number,
  ): Promise<void> {
    await this.request({
      method: 'DELETE',
      path: `${this.baseUrl}/${teamId}/members/${teamMemberId}`,
    });
  }

  public async deleteInvite(
    teamId: number,
    teamInviteId: string,
  ): Promise<void> {
    await this.request({
      method: 'DELETE',
      path: `${this.baseUrl}/${teamId}/invites/${teamInviteId}`,
    });
  }

  public async deleteTeam(teamId: number): Promise<Team> {
    return this.request({
      method: 'DELETE',
      path: `${this.baseUrl}/${teamId}`,
    });
  }

  public async getLenses(teamId: number): Promise<TeamLens[]> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/${teamId}/lenses`,
    });
  }

  public async addLens(teamId: number, lensId: number): Promise<TeamLens> {
    return this.request({
      method: 'POST',
      path: `${this.baseUrl}/${teamId}/lenses`,
      body: {
        lensId,
      },
    });
  }

  public async deleteLens(teamId: number, lensId: number): Promise<TeamLens> {
    return this.request({
      method: 'DELETE',
      path: `${this.baseUrl}/${teamId}/lenses/${lensId}`,
    });
  }

  public async generateInviteCode(
    teamId: number,
  ): Promise<GenerateInviteCodeResponse> {
    return this.request({
      method: 'PUT',
      path: `${this.baseUrl}/${teamId}/invite`,
    });
  }

  public async getSubscription(teamId: number): Promise<Subscription | null> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/${teamId}/subscriptions`,
    });
  }

  public async setLicenses(teamId: number, quantity: number): Promise<void> {
    await submitForm({
      url: `${process.env.NEXT_PUBLIC_API_BASE}${this.baseUrl}/${teamId}/set-licenses`,
      params: {
        quantity: String(quantity),
      },
      newTab: true,
    });
  }

  async getAdditionalSeatPrice(teamId: number): Promise<{
    total: number;
    prorated: number;
  }> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/${teamId}/seat-price`,
    });
  }

  async getAddonPrice(teamId: number, type: AddonType): Promise<number> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/${teamId}/addon-price`,
      query: {
        type,
      },
    });
  }

  async purchaseAddon(type: AddonType, teamId: number): Promise<void> {
    return this.request({
      method: 'POST',
      path: `${this.baseUrl}/${teamId}/purchase-addon`,
      body: {
        type,
      },
    });
  }

  async removeAddon(type: AddonType, teamId: number): Promise<void> {
    return this.request({
      method: 'POST',
      path: `${this.baseUrl}/${teamId}/remove-addon`,
      body: {
        type,
      },
    });
  }

  async getAddons(
    teamId: number,
  ): Promise<[{ type: AddonType; status: 'active' | 'canceled' }]> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/${teamId}/addons`,
    });
  }
}

const api = new TeamApi();

export const useTeamById = (id?: number): UseQueryResult<Team> =>
  useQuery(keys.id(id || -1), () => (id ? api.getById(id) : null), {
    enabled: !!id,
  });

export function useTeams(): UseQueryResult<Team[], Error> {
  return useQuery(keys.own, () => api.getOwnTeams());
}

export function useInvites(): UseQueryResult<TeamInvite[], Error> {
  return useQuery(keys.invites, () => api.getInvites());
}

export function useSlugSuggestion(): UseMutationResult<
  SlugSuggestionResponse,
  Error,
  SlugSuggestionInput
> {
  return useMutation(({ name }) => api.getSlugSuggestion(name));
}

export function useCreateTeam(): UseMutationResult<Team, Error, TeamInput> {
  return useMutation(
    ({ name, slug, autoJoin }) => api.createTeam(name, slug, autoJoin),
    {
      async onSuccess(team: Team) {
        const teams = queryClient.getQueryData<Team[]>(keys.own);

        queryClient.setQueryData(keys.own, teams ? teams.concat(team) : [team]);
      },
    },
  );
}

export function useUpdateTeam(): UseMutationResult<
  Team,
  Error,
  UpdateTeamInput
> {
  return useMutation(
    ({ name, slug, autoJoin, teamId }) =>
      api.updateTeam(teamId, name, slug, autoJoin),
    {
      async onSuccess(team: Team) {
        const teams = queryClient.getQueryData<Team[]>(keys.own);

        queryClient.setQueryData(
          keys.own,
          teams?.map((t) => (t.teamId === team.teamId ? team : t)),
        );
      },
    },
  );
}

export function useLeaveTeam(): UseMutationResult<
  TeamMember,
  Error,
  LeaveTeamInput
> {
  return useMutation(({ teamId }) => api.leaveTeam(teamId), {
    async onSuccess(_, { teamId }) {
      const teams = queryClient.getQueryData<Team[]>(keys.own);

      queryClient.setQueryData(
        keys.own,
        teams?.filter((team) => team.teamId !== teamId),
      );
    },
  });
}

export function useAcceptInvite(): UseMutationResult<
  TeamMember,
  Error,
  AcceptInviteInput
> {
  return useMutation(({ teamId }) => api.acceptInvite(teamId), {
    async onSuccess(member, { teamId }) {
      const teams = queryClient.getQueryData<Team[]>(keys.own);
      const invites = queryClient.getQueryData<TeamInvite[]>(keys.invites);

      const team = invites?.find((invite) => invite.teamId === teamId)?.team;

      queryClient.setQueryData(
        keys.own,
        teams
          ? [
              ...teams,
              {
                ...team,
                teamMembers: team?.teamMembers.concat(member),
                teamInvites: team?.teamInvites.filter(
                  (invite) => invite.userId !== member.user.userId,
                ),
              },
            ]
          : [],
      );
      queryClient.setQueryData(
        keys.invites,
        invites?.filter((invite) => invite.teamId !== teamId),
      );
    },
  });
}

export function useDeclineInvite(): UseMutationResult<
  TeamInvite,
  Error,
  DeclineInviteInput
> {
  return useMutation(({ teamId }) => api.declineInvite(teamId), {
    async onSuccess(_, { teamId }) {
      const invites = queryClient.getQueryData<TeamInvite[]>(keys.invites);

      queryClient.setQueryData(
        keys.invites,
        invites?.filter((invite) => invite.teamId !== teamId),
      );
    },
  });
}

export function useInviteMembers(): UseMutationResult<
  TeamInvite[],
  WaldoApiError,
  InviteMembersInput
> {
  return useMutation(
    ({ teamId, emails }) => api.inviteMembers(teamId, emails),
    {
      async onSuccess(teamInvites: TeamInvite[], { teamId }) {
        const teams = queryClient.getQueryData<Team[]>(keys.own);

        queryClient.setQueryData(
          keys.own,
          teams?.map((team) =>
            team.teamId === teamId
              ? { ...team, teamInvites: [...team.teamInvites, ...teamInvites] }
              : team,
          ),
        );
      },
    },
  );
}

export function useGenerateInviteCode(): UseMutationResult<
  GenerateInviteCodeResponse,
  Error,
  GenerateInviteCodeInput
> {
  return useMutation(({ teamId }) => api.generateInviteCode(teamId), {
    async onSuccess({ inviteCode }, { teamId }) {
      const teams = queryClient.getQueryData<Team[]>(keys.own);

      queryClient.setQueryData(
        keys.own,
        teams?.map((team) =>
          team.teamId === teamId ? { ...team, inviteCode } : team,
        ),
      );
    },
  });
}

export function useChangeMemberRole(): UseMutationResult<
  TeamMember,
  Error,
  ChangeRoleInput
> {
  return useMutation(
    ({ teamMemberId, teamId, role }) =>
      api.changeMemberRole(teamId, teamMemberId, role),
    {
      onSuccess(member, { teamId }) {
        const teams = queryClient.getQueryData<Team[]>(keys.own);

        queryClient.setQueryData(
          keys.own,
          teams?.map((team) =>
            team.teamId === teamId
              ? {
                  ...team,
                  teamMembers: team.teamMembers.map((teamMember) =>
                    teamMember.teamMemberId === member.teamMemberId
                      ? member
                      : teamMember,
                  ),
                }
              : team,
          ),
        );
      },
    },
  );
}

export function useChangeInviteRole(): UseMutationResult<
  TeamInvite,
  Error,
  ChangeInviteRoleInput
> {
  return useMutation(
    ({ teamId, role, teamInviteId }) =>
      api.changeInviteRole(teamId, teamInviteId, role),
    {
      onSuccess(invite, { teamId }) {
        const teams = queryClient.getQueryData<Team[]>(keys.own);

        queryClient.setQueryData(
          keys.own,
          teams?.map((team) =>
            team.teamId === teamId
              ? {
                  ...team,
                  teamInvites: team.teamInvites.map((teamInvite) =>
                    teamInvite.teamInviteId === invite.teamInviteId
                      ? invite
                      : teamInvite,
                  ),
                }
              : team,
          ),
        );
      },
    },
  );
}

export function useResendInvite(): UseMutationResult<
  TeamInvite,
  Error,
  ResendInviteInput
> {
  return useMutation(({ teamId, teamInviteId }) =>
    api.resendInvite(teamId, teamInviteId),
  );
}

export function useDeleteMember(): UseMutationResult<
  void,
  Error,
  DeleteMemberInput
> {
  return useMutation(
    ({ teamId, teamMemberId }) => api.deleteMember(teamId, teamMemberId),
    {
      onSuccess(_, { teamId, teamMemberId }) {
        const teams = queryClient.getQueryData<Team[]>(keys.own);

        queryClient.setQueryData(
          keys.own,
          teams?.map((team) =>
            team.teamId === teamId
              ? {
                  ...team,
                  teamMembers: team.teamMembers.filter(
                    (teamMember) => teamMember.teamMemberId !== teamMemberId,
                  ),
                }
              : team,
          ),
        );
      },
    },
  );
}

export function useDeleteInvite(): UseMutationResult<
  void,
  Error,
  DeleteInviteInput
> {
  return useMutation(
    ({ teamId, teamInviteId }) => api.deleteInvite(teamId, teamInviteId),
    {
      onSuccess(_, { teamId, teamInviteId }) {
        const teams = queryClient.getQueryData<Team[]>(keys.own);

        queryClient.setQueryData(
          keys.own,
          teams?.map((team) =>
            team.teamId === teamId
              ? {
                  ...team,
                  teamInvites: team.teamInvites.filter(
                    (invite) => invite.teamInviteId !== teamInviteId,
                  ),
                }
              : team,
          ),
        );
      },
    },
  );
}

export function useDeleteTeam(): UseMutationResult<Team, Error, number> {
  return useMutation((teamId) => api.deleteTeam(teamId), {
    onSuccess(_, teamId) {
      const teams = queryClient.getQueryData<Team[]>(keys.own);

      queryClient.setQueriesData(
        keys.own,
        teams?.filter((team) => team.teamId !== teamId),
      );
    },
  });
}

export function useTeamLenses(teamId: number): UseQueryResult<TeamLens[]> {
  return useQuery(keys.lenses(teamId), () => api.getLenses(teamId));
}

export function useAddTeamLens(): UseMutationResult<
  TeamLens,
  Error,
  AddTeamLensInput
> {
  return useMutation(({ teamId, lensId }) => api.addLens(teamId, lensId), {
    onSuccess(_, { teamId }) {
      queryClient.removeQueries(keys.own);
      queryClient.removeQueries(['lens', 'teams']);
      queryClient.removeQueries(keys.lenses(teamId));
    },
  });
}

export function useDeleteTeamLens(): UseMutationResult<
  TeamLens,
  Error,
  DeleteTeamLensInput
> {
  return useMutation(({ teamId, lensId }) => api.deleteLens(teamId, lensId), {
    onSuccess(_, { teamId }) {
      queryClient.removeQueries(keys.own);
      queryClient.removeQueries(['lens', 'teams']);
      queryClient.removeQueries(keys.lenses(teamId));
    },
  });
}

const firstTeamState = atom<boolean>({
  key: 'firstTeam',
  default: true,
  effects: [localStorageEffect],
});

export const useFirstTeam = (): boolean => useRecoilValue(firstTeamState);

export const useSetFirstTeam = (): SetterOrUpdater<boolean> =>
  useSetRecoilState(firstTeamState);

export const selectedTeamState = atom<Team | undefined>({
  key: 'selectedTeam',
  default: undefined,
  effects: [
    // Only for compatibility with new web
    ({ onSet }: Parameters<AtomEffect<Team | undefined>>[0]): void => {
      onSet((newValue, _) => {
        localStorage.setItem(
          'workspace',
          JSON.stringify({
            state: { workspace: newValue?.teamId || null },
            version: 0,
          }),
        );
      });
    },
  ],
});

export const useSelectedTeam = (): Team | undefined =>
  useRecoilValue(selectedTeamState);

export const useSetSelectedTeam = (): SetterOrUpdater<Team | undefined> =>
  useSetRecoilState(selectedTeamState);

const teamInitialState = atom<Partial<FormFields>>({
  key: 'teamInitialState',
  default: {},
});

export const useTeamInitialState = (): Partial<FormFields> =>
  useRecoilValue(teamInitialState);

export const useSetTeamInitialState = (): SetterOrUpdater<Partial<Team>> =>
  useSetRecoilState(teamInitialState);

export const useTeamsWithSharedLensAttribute = ({
  lensId,
  name,
  skip,
  limit,
}: {
  lensId?: number;
  name?: string;
  skip?: number;
  limit?: number;
}): UseInfiniteQueryResult<TeamWithSharedLensAttribute[]> =>
  useInfiniteQuery(
    keys.teams({ lensId, name, skip, limit }),
    ({ pageParam }) =>
      api.getTeamsWithSharedLensAttribute(
        lensId,
        name,
        pageParam || skip,
        limit,
      ),
    {
      getNextPageParam,
    },
  );

export const useTeamsBySlug = (slug: string): UseQueryResult<Team[]> =>
  useQuery(keys.teamsBySlug(slug), () => api.getTeamsBySlug(slug));

export const useTeamSubscription = (
  teamId?: number,
): UseQueryResult<Subscription> =>
  useQuery(
    keys.subscription(teamId),
    () => (teamId ? api.getSubscription(teamId) : null),
    {
      enabled: !!teamId,
      refetchOnWindowFocus: true,
    },
  );

export const useGetTeamSubscription = (): UseMutationResult<
  Subscription | null,
  Error,
  number
> =>
  useMutation((teamId: number) => api.getSubscription(teamId), {
    onSuccess(subscription) {
      if (subscription?.teamId) {
        queryClient.setQueryData(
          keys.subscription(subscription.teamId),
          subscription,
        );
      }
    },
  });

export const useSetLicenses = (): UseMutationResult<
  void,
  Error,
  { teamId: number; quantity: number }
> => useMutation(({ teamId, quantity }) => api.setLicenses(teamId, quantity));
