export const submitForm = ({
  url,
  params,
  newTab,
}: {
  url: string;
  params: Record<string, string>;
  newTab?: boolean;
}) => {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = url;

  Object.keys(params).forEach((key) => {
    if (typeof params[key] === 'string') {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = params[key] as string;
      form.appendChild(input);
    }
  });

  if (newTab) {
    form.target = '_blank';
  }

  form.onsubmit = (e) => e.preventDefault();

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};
